/**
 * select2 customisation for PNP CMS
 * CraigE 29-Aug-2023
 */

$(function() {
    const formatSelectText = function (icon) {
        return $('<span><i class="fas ' + $(icon.element).data('icon') + '"></i> ' + icon.text + '</span>');
    };

    /* A matcher function for select2 that shows all items in an optgroup, if the optgroup
     * matches the filter. This is not the default behaviour, which just shows the optgroup label.
     * Needed to meet specification.
     */
    const optgroupMatcher = function(params, data) {
        // If there are no search terms, return all the data
        if ($.trim(params.term) === '') {
            return data;
        }
        if (typeof data.text === 'undefined') {
            return null;
        }

        // searched text
        const search = params.term.toUpperCase();
        // regex to find a match at the start of the option text
        const regex = `^${search}`;

        if (data.children !== undefined) {
            /* An optgroup */
            let matchedChild = false;
            let modifiedData = $.extend({}, data, true);
            modifiedData.children = [];
            for (let x = 0; x < data.children.length; ++x) {
                if (data.children[x].text.toUpperCase().trim().match(regex)) {
                    matchedChild = true;
                    modifiedData.children.push(data.children[x]);
                }
            }
            if (matchedChild && modifiedData.children.length === 0) {
                return null;
            }
            if (!matchedChild && data.element.getAttribute('label').toUpperCase().trim().match(regex)) {
                return data;
            }
            return modifiedData;
        } else {
            if (data.text.toUpperCase().trim().match(regex)) {
                return data;
            }
            return null;
        }
    };

    window.initialiseSelect2 = () => {

        $('.select-multiple').not(".can-clear").select2({
            placeholder: $(this).hasClass("select-client") ? "Select Client" : "Select",
            closeOnSelect: false,
            templateSelection: formatSelectText,
            templateResult: formatSelectText,
            matcher: optgroupMatcher
        });

        $('.select-multiple-full').not(".can-clear").select2({
            placeholder: $(this).hasClass("select-client") ? "Select Client" : "Select",
            closeOnSelect: false,
            style: 'width: 100% !important',
            templateSelection: formatSelectText,
            templateResult: formatSelectText,
            matcher: optgroupMatcher
        });

        $('.select-multiple.can-clear').select2({
            placeholder: $(this).hasClass("select-client") ? "Select Client" : "Select",
            closeOnSelect: false,
            allowClear: true,
            templateSelection: formatSelectText,
            templateResult: formatSelectText,
            matcher: optgroupMatcher
        });

        $('.select-multiple-full.can-clear').select2({
            placeholder: $(this).hasClass("select-client") ? "Select Client" : "Select",
            closeOnSelect: false,
            allowClear: true,
            style: 'width: 100% !important',
            templateSelection: formatSelectText,
            templateResult: formatSelectText,
            matcher: optgroupMatcher
        });

        const clearable = $('.select-single.can-clear, .select-one.can-clear');

        clearable.select2({
            placeholder: $(this).hasClass("select-client") ? "Select Client" : "Select",
            closeOnSelect: true,
            allowClear: true,
            templateSelection: formatSelectText,
            templateResult: formatSelectText,
            matcher: optgroupMatcher
        });

        $('.select-single, .select-one').not(".can-clear").each(function () {
            $(this).select2({
                dropdownParent: $(this).parent(),
                placeholder: $(this).hasClass("select-client") ? "Select Client" : "Select",
                closeOnSelect: true,
                templateSelection: formatSelectText,
                templateResult: formatSelectText,
                matcher: optgroupMatcher
            });
        });
        clearable.each(function () {
            $(this).select2({
                dropdownParent: $(this).parent(),
                placeholder: $(this).hasClass("select-client") ? "Select Client" : "Select",
                closeOnSelect: true,
                allowClear: true,
                templateSelection: formatSelectText,
                templateResult: formatSelectText,
                matcher: optgroupMatcher
            });
        });

        function removeSelect2Errors(select_field) {
            // remove the error class from the select
            $(select_field).removeClass('error');

            // get the error message label and remove it from the page
            const error_label = $(select_field).parent('div').children('label.error');
            if (error_label.length === 1) {
                error_label.remove();
            }
        }

        const $select = $('select');

        $select.on('select2:close', function (e) {
            if ($(this).data('select2') && !$(this).hasClass('select-multiple-full')) {
                let uldiv = $(this).siblings('.select2-container');
                let count = $(this).select2('data').length;

                // if an option is selected remove the error messages if present
                if (count > 0) {
                    removeSelect2Errors(this);
                }

                uldiv.find('.select2-selection__choice__display').each(function(index, value) {
                    if( $(value).find('span').text().length <= 1 ) {
                        $(value).parent().remove();
                    }
                });

                if (uldiv.height() > 50) {
                    uldiv.find('.select2-selection__rendered').html("<div class='count' style='line-height: 38px;padding-left: 15px;'>" + count + " Items Selected</div>");
                }
            }
        });

        $select.trigger({type: 'select2:close'});

        // autofocus search field when opening a select2 dropdown
        $(document).on('select2:open', function (e) {
            const searchBar = document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`)
            if (typeof (searchBar) !== "undefined" && searchBar !== null) {
                searchBar.focus();
            }
        });

        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')),
            tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl);
        });
    }

    window.initialiseSelect2();

});
